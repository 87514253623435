<template>
  <Header title="Home" />
  <!-- BODY SECTION START -->
  <!----- SLIDER SECTION ----->
  <section class="home_slider_container">
    <div class="home_slider_section margin_top">
      <div class="home_slider_row overflow_hidden">
        <div class="home_slider_col">
          <div class="home_slider_block">
            <div class="home_image">
              <video loop muted playsinline autoplay>
                <source :src="rio_video" :alt="rio_video" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- VIILA BOOKING FORM ---------- -->
  <section class="booking_form_section">
    <div class="booking_form_row">
      <div class="booking_form_col">
        <div class="booking_form_block">
          <form @submit.prevent="submitForm" method="post">
            <div class="field_form_input_box">
              <div class="elem-group inlined date-group">
                <label for="checkin-date" class="font_18">Check In</label>
                <Datepicker
                  type="date"
                  id="checkout-date"
                  name="checkout"
                  v-model="form.check_in_date"
                  format="dd-MM-yyyy"
                  value-format="dd-MM-yyyy"
                  auto-apply
                  :min-date="new Date()"
                  @closed="closeCal"
                  required
                />
              </div>
              <div class="elem-group inlined date-group">
                <label for="checkout-date" class="font_18">Check Out</label>
                <Datepicker
                  type="date"
                  id="checkout-date"
                  name="checkout"
                  v-model="form.check_out_date"
                  format="dd-MM-yyyy"
                  value-format="dd-MM-yyyy"
                  :min-date="new Date()"
                  auto-apply
                  required
                />
              </div>
              <div class="elem-group inlined small_input">
                <label for="total_adult" class="font_18">Adults</label>
                <div class="field_block">
                  <input
                    type="number"
                    id="total_adult"
                    name="total_adult"
                    v-model="form.total_adult"
                    placeholder="2"
                    min="1"
                    required
                    @keypress="isNumber"
                  />
                </div>
              </div>
              <div class="elem-group inlined small_input">
                <label for="total_child" class="font_18">Children</label>
                <div class="field_block">
                  <input
                    type="number"
                    id="total_child"
                    name="total_child"
                    v-model="form.total_child"
                    placeholder="2"
                    min="0"
                  />
                </div>
              </div>
              <div class="elem-group inlined small_input">
                <label for="qty" class="font_18">Villa Quantity</label>
                <div class="field_block">
                  <input
                    type="number"
                    id="qty"
                    name="qty"
                    v-model="form.qty"
                    placeholder="1"
                    min="1"
                    required
                  />
                </div>
              </div>
            </div>

            <button type="submit" class="book_button">Book Now</button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- VILLAS FOR RENT ---------- -->
  <section class="pro_box_section villas_for_rent">
    <div class="pro_box_row row_padding main_container left_vector">
      <div class="vector_outter_box">
        <img :src="single_title_png" alt="" class="vector_animation" />
        <div class="svg_move">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 682.45 89.75"
          >
            <defs></defs>
            <title>arrow-right</title>
            <path
              class="cls-1"
              d="M52.78,384.35h2.09"
              transform="translate(494.02 -294.6)"
            />
            <line class="cls-1" x1="558.28" y1="87.14" x2="682.45" y2="65.75" />
            <line class="cls-1" x1="622.91" y1="65.6" x2="607.91" y2="66.94" />
            <line class="cls-1" x1="631.84" y1="66.27" x2="630.8" y2="66.27" />
            <path
              class="cls-2"
              d="M-493.83,314.57c51.06-21,91.81-20.73,114.87-18.44,32.13,3.19,61.86,13.51,85,21.57,36.35,12.62,36.06,17,62.08,24.52,40.69,11.78,74.72,10.78,98.09,9.91a367.24,367.24,0,0,0,99.65-17.74"
              transform="translate(494.02 -294.6)"
            />
            <path
              class="cls-2"
              d="M-40.87,323.85l15,5.85-6.82,14.73"
              transform="translate(494.02 -294.6)"
            />
          </svg>
        </div>
      </div>
      <div class="pro_box_col" id="villas_slider">
        <div class="main_title_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading tc">
              Indulge in luxury, explore our villas.
            </p>
          </div>
        </div>
        <div class="main_box_block">
          <Carousel
            ref="villas"
            :breakpoints="sliders.villas.breakpoints"
            :wrap-around="true"
          >
            <Slide key="1">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="villa01" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/platinum-villa"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/platinum-villa"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">Platinum Villa</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide key="2">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="villa02" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/diamond-villa"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/diamond-villa"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">Diamond Villa</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide key="3">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="villa03" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/cottage">
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/cottage">
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">
                                Exclusive Cottages
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- WHY CHOICE RIO ---------- -->
  <section class="wcu_section">
    <!-- row_padding -->
    <div class="wcu_row main_container">
      <div class="wcu_col">
        <div class="wcu_block">
          <div class="main_title_block">
            <div class="body_heading_block desk_heading">
              <p class="body_heading">Enjoy luxury at your leisure!</p>
              <p class="body_font">
                Our properties at Rio Colina blend seamlessly with the natural
                habitat while offering a perfect amalgamation of luxury,
                leisure, convenience, and natural beauty.
              </p>
            </div>
          </div>
        </div>
        <div class="wcu_data_block">
          <div class="data_outter">
            <div class="data_inner">
              <div class="data_img_box">
                <img :src="WRC01" class="data_img" />
              </div>
              <div class="data_info_box">
                <p class="data_sub_heading">Premium Collection Of Villas</p>
                <p class="small_body_font">
                  Retreat to comfort at our luxurious rooms and cottages and
                  enjoy the perfect weekend getaway amidst sparkling water and
                  manicured lawns. Experience peace and tranquility at the most
                  awe-inspiring destination.
                </p>
              </div>
            </div>
            <div class="data_inner">
              <div class="data_img_box">
                <img :src="WRC02" class="data_img" />
              </div>
              <div class="data_info_box">
                <p class="data_sub_heading">Personalized Experience</p>
                <p class="small_body_font">
                  It gives us great pleasure to welcome guests to our villa and
                  provide them with a high level of personal service
                  specifically designed to exceed their expectations. Our
                  dedicated team of personnel is at your service to ensure you
                  have an extraordinary experience.
                </p>
              </div>
            </div>
            <div class="data_inner">
              <div class="data_img_box">
                <img :src="WRC03" class="data_img" />
              </div>
              <div class="data_info_box">
                <p class="data_sub_heading">5 Star Amenities</p>
                <p class="small_body_font">
                  With a premium spa center, Jacuzzi beds, Amphitheater, and so
                  much more, Rio Colina is designed and equipped with activities
                  for an exciting and fun weekend with your family!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- PLEASANTRIES TAB SECTION ---------- -->
  <section class="desktop_tab_view_section plea_tab_section row_padding">
    <div class="p_tab_row main_container">
      <div class="p_tab_col">
        <div class="p_tab_block">
          <!-- TAB BODY -->

          <div class="naccs">
            <div class="tab_content_grid grid">
              <div class="tab_content_block gc gc--2-of-3">
                <ul class="tab_content_ul nacc">
                  <li class="tab_content_outter_box active">
                    <div class="tab_content_inner_box">
                      <!-- body tab content -->
                      <div class="tab_main_in_block">
                        <div class="tab_img_box">
                          <img :src="tab_01" alt="" class="tab_img" />
                        </div>
                        <div class="tab_point_outter_box">
                          <div class="tab_point_inner_box">
                            <div class="tab_point_ul">
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_1"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Banquet hall
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_2"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  2 Cinemas
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_3"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Rudraa Restro/ Multicuisine Restaurant
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_4"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Discotheque
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_5"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Swimming pool with vanishing edge
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_6"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Jacuzzi beds
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_7"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Slides for kids
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_8"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Kids pool
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_10"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Spa / Sauna
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_11"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Steam / Jacuzzi
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_12"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">Lounge</p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_13"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Conference Room
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_14"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">Library</p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_16"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Changing Rooms (male/female)
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_17"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Clubhouse Store Room
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  :src="tab_point_18"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Gymnasium with Aerobics
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end -->
                    </div>
                  </li>
                  <li class="tab_content_outter_box">
                    <div class="tab_content_inner_box">
                      <!-- body tab content -->
                      <div class="tab_main_in_block">
                        <div class="tab_img_box">
                          <img :src="tab_02" alt="" class="tab_img" />
                        </div>
                        <div class="tab_point_outter_box">
                          <div class="tab_point_inner_box">
                            <div class="tab_point_ul">
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Squash.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">Squash</p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Badminton.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Badminton
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Kids Game Zone.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Infant/Kids Game Zone
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Carrom.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Card/Chess/Carrom
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Billiards and Snooker.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Billiards and Snooker
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Skating Rink.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Skating Rink
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Jogging Track.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Jogging Track
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/inoutdoor/Children’s Play Area.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Children’s Play Area
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end -->
                    </div>
                  </li>
                  <li class="tab_content_outter_box">
                    <div class="tab_content_inner_box">
                      <!-- body tab content -->
                      <div class="tab_main_in_block">
                        <div class="tab_img_box">
                          <img :src="tab_03" alt="" class="tab_img" />
                        </div>
                        <div class="tab_point_outter_box">
                          <div class="tab_point_inner_box">
                            <div class="tab_point_ul">
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Provision Store.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Provision Store
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Intercom.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">Intercom</p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Security Systems.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Security Systems
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Laundry Facilities.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Laundry Facilities
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Flexible Housekeeping.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Flexible Housekeeping
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Medical - First Aid Center.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Medical - First Aid Center
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Smoke & Fire Detectors.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Smoke & Fire Detectors
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Fire Extinguishers.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Fire Extinguishers
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Maintenance Store.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Maintenance Store
                                </p>
                              </div>
                              <div class="tab_point_li">
                                <img
                                  src="../../src/assets/images/home/genamenities/Reception & Administration.png"
                                  alt=""
                                  class="tab_point_img"
                                />
                                <p class="tab_point_text body_font">
                                  Reception & Administration
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end -->
                    </div>
                  </li>
                </ul>
                <div class="swiper_close">
                  <div class="swiper_close_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 252 252"
                      style="enable-background: new 0 0 252 252"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          d="M126,0C56.523,0,0,56.523,0,126s56.523,126,126,126s126-56.523,126-126S195.477,0,126,0z M126,234   c-59.551,0-108-48.449-108-108S66.449,18,126,18s108,48.449,108,108S185.551,234,126,234z"
                        />
                        <path
                          d="M164.612,87.388c-3.515-3.515-9.213-3.515-12.728,0L126,113.272l-25.885-25.885c-3.515-3.515-9.213-3.515-12.728,0   c-3.515,3.515-3.515,9.213,0,12.728L113.272,126l-25.885,25.885c-3.515,3.515-3.515,9.213,0,12.728   c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636L126,138.728l25.885,25.885c1.757,1.757,4.061,2.636,6.364,2.636   s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728L138.728,126l25.885-25.885   C168.127,96.601,168.127,90.902,164.612,87.388z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="tab_btn_gc gc gc--1-of-3">
                <p class="body_heading">Pleasantries</p>
                <div class="tab_btn_menu menu">
                  <div class="tab_btn active">
                    <div class="tabing_outter">
                      <div class="tabing_inner">
                        <div class="tabing_img_box">
                          <img :src="tab_01" class="tabing_img" />
                        </div>
                        <div class="tabing_heading_box">
                          <p class="box_title_text">Club Rio Amenities</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab_btn">
                    <div class="tabing_outter">
                      <div class="tabing_inner">
                        <div class="tabing_img_box">
                          <img :src="tab_02" class="tabing_img" />
                        </div>
                        <div class="tabing_heading_box">
                          <p class="box_title_text">Indoor / Outdoor Games</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab_btn">
                    <div class="tabing_outter">
                      <div class="tabing_inner">
                        <div class="tabing_img_box">
                          <img :src="tab_03" class="tabing_img" />
                        </div>
                        <div class="tabing_heading_box">
                          <p class="box_title_text">General Amenities</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- END -->
        </div>
      </div>
      <!-- <div class="right_vector_outter_box">
        <div class="right_svg_move">
          <svg width="488" height="206" viewBox="0 0 488 206" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M487.5 205.5C487.5 205.5 449.5 150 316.5 172.5C183.5 195 218.5 111.5 10.5 12" stroke="black" stroke-dasharray="2 2"/>
            <path d="M17.0908 1.65967L1.93057 7.73847L9.28128 22.7263" stroke="black" stroke-dasharray="2 2"/>
          </svg>
        </div>
        <img :src="right_single_title" alt="" class="right_vector_animation">
      </div> -->
    </div>
  </section>
  <!-- ---------- HOW IT WORK SECTION ---------- -->
  <section class="work_section row_padding">
    <div class="work_row main_container">
      <div class="work_col">
        <div class="main_title_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading tc">How It Works</p>
          </div>
        </div>
        <div class="work_block">
          <div class="mini_box_inner two_arrow_img arrow01">
            <div class="number_circle_box">
              <p class="data_sub_heading tc number_circle">01</p>
            </div>
            <div class="mini_box_img_box">
              <img :src="work01" class="mini_box_img" />
            </div>
            <p class="data_sub_heading tc">Select A Villa</p>
            <p class="small_body_font tc">
              Choose from a wide range of platinum, diamond villas, and
              exclusive cottages as per your need to bask in a pool of comfort,
              soothing, and pleasant experience.
            </p>
            <!-- <div class="mini_box_info_box">
              <div class="number_circle_box">
                <p class="data_sub_heading tc number_circle">01</p>
              </div>
              <p class="data_sub_heading tc">Select A Villa</p>
              <p class="small_body_font tc">Assessment and distinction between positive and negative impacts, direct and indirect impacts, and immediate and long-term impacts likely.</p>
            </div> -->
          </div>
          <div class="mini_box_inner two_arrow_img arrow02">
            <div class="number_circle_box">
              <p class="data_sub_heading tc number_circle">02</p>
            </div>
            <div class="mini_box_img_box">
              <img :src="work03" class="mini_box_img" />
            </div>
            <p class="data_sub_heading tc">Login and Payment</p>
            <p class="small_body_font tc">
              Get yourself registered and log in from the account. Make the
              payment online to book the villa of your choice.
            </p>
            <!-- <div class="mini_box_info_box">
              <div class="number_circle_box">
                <p class="data_sub_heading tc number_circle">02</p>
              </div>
              <p class="data_sub_heading tc">Login And Payment</p>
              <p class="small_body_font tc">Assessment and distinction between positive and negative impacts, direct and indirect impacts, and immediate and long-term impacts likely.</p>
            </div> -->
          </div>
          <div class="mini_box_inner">
            <div class="number_circle_box">
              <p class="data_sub_heading tc number_circle">03</p>
            </div>
            <div class="mini_box_img_box">
              <img :src="work02" class="mini_box_img" />
            </div>
            <p class="data_sub_heading tc">Enjoy the stay</p>
            <p class="small_body_font tc">
              Experience your stay's peace, relaxation, and comfort with our
              3-star amenities to create an unforgettable memory.
            </p>
            <!-- <div class="mini_box_info_box">
              <div class="number_circle_box">
                <p class="data_sub_heading tc number_circle">03</p>
              </div>
              <p class="data_sub_heading tc">Enjoy The Stay</p>
              <p class="small_body_font tc">Assessment and distinction between positive and negative impacts, direct and indirect impacts, and immediate and long-term impacts likely.</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- EXPLORE THE WORLD OF LUXURY ---------- -->
  <section class="pro_box_section explore_luxury_section" id="moments_slider">
    <div class="explore_luxury_row row_padding main_container">
      <div class="pro_box_col">
        <div class="main_title_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading tc">Let’s create special moments!</p>
          </div>
        </div>
        <div class="main_box_block">
          <Carousel
            ref="villas"
            :breakpoints="sliders.moments.breakpoints"
            :wrap-around="true"
          >
            <Slide key="1">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="Luxury01" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/one-day-picnic"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link
                              class="site_button"
                              to="/one-day-picnic"
                            >
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">One Day Picnic</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide key="2">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="Luxury02" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/wedding">
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/wedding">
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">Wedding Functions</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide key="3">
              <div class="pro_box_block">
                <div class="box_outter_box">
                  <div class="box_inner_box">
                    <div class="box_img_block">
                      <img :src="Luxury03" class="box_img" />
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/event">
                              <div class="site_button_div">
                                <span class="btn_text">View More</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                          <div class="bird_hover_opacity bird bird--one">
                            <!-- <a href="#" class="site_button"> -->
                            <router-link class="site_button" to="/event">
                              <div class="site_button_div">
                                <span class="btn_text">Book Now</span>
                              </div>
                            </router-link>
                            <!-- </a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box_data_block">
                      <div class="box_data">
                        <div
                          class="box_body_text text_center box_details_block"
                        >
                          <div class="box_details">
                            <div class="box_title_text_box">
                              <p class="box_title_text tc">Corporate Events</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- ABOUT US SECTION ---------- -->
  <section class="about_us_section about_us_vector row_padding for_desktop">
    <div class="about_us_row main_container row_padding">
      <div class="about_us_img_col">
        <div class="about_us_img_block">
          <div class="multiple_img_block">
            <img
              :src="about_img_01"
              class="multi_img m_img_01"
              id="wrapper01"
            />
            <img
              :src="about_img_02"
              class="multi_img m_img_02"
              id="wrapper02"
            />
            <img
              :src="about_img_03"
              class="multi_img m_img_03"
              id="wrapper03"
            />
          </div>
        </div>
      </div>
      <div class="about_us_col">
        <div class="about_us_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">About Us</p>
            <div class="small_body_font body_scroll">
              <p>
                Rio Colina is a group of premium and sophisticated villas that
                promises to deliver experiences that are spirited, dynamic, and
                unique - a fit made for a perfect getaway. Set in the raised
                terrain of a hill, our property is spread across 48 acres of
                lush greenery. Overlooking the Tapi river, this luxurious place
                exudes grandeur and elegance of the highest stature. Hop on a
                sumptuous ride of 3-star amenities and experience a feeling like
                never before! We leave no stone unturned to ensure your stay
                with us is a truly memorable one in terms of service, dining,
                and entertainment.
              </p>
              <p>
                Savor the authentic taste of luxury and class with villa booking
                at Rio Colina.
              </p>
            </div>
            <div class="bird bird--one">
              <div class="btn_border block_cta">
                <router-link to="/about-us" class="site_button" tabindex="0">
                  <div class="site_button_div">
                    <span class="btn_text">Read More</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about_us_section about_us_vector row_padding for_other">
    <div class="about_us_row main_container row_padding">
      <div class="about_us_img_col">
        <div class="about_us_img_block">
          <div class="multiple_img_block">
            <img :src="about_img_01" class="multi_img m_img_01" />
            <img :src="about_img_02" class="multi_img m_img_02" />
            <img :src="about_img_03" class="multi_img m_img_03" />
          </div>
        </div>
      </div>
      <div class="about_us_col">
        <div class="about_us_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">About Us</p>
            <div class="small_body_font body_scroll">
              <p>
                Rio Colina is a group of premium and sophisticated villas that
                promises to deliver experiences that are spirited, dynamic, and
                unique - a fit made for a perfect getaway. Set in the raised
                terrain of a hill, our property is spread across 48 acres of
                lush greenery. Overlooking the Tapi river, this luxurious place
                exudes grandeur and elegance of the highest stature. Hop on a
                sumptuous ride of 5-star amenities and experience a feeling like
                never before! We leave no stone unturned to ensure your stay
                with us is a truly memorable one in terms of service, dining,
                and entertainment.
              </p>
              <p>
                Savor the authentic taste of luxury and class with villa booking
                at Rio Colina.
              </p>
            </div>
            <div class="bird bird--one">
              <div class="btn_border block_cta">
                <router-link to="/about-us" class="site_button" tabindex="0">
                  <div class="site_button_div">
                    <span class="btn_text">Read More</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ----------- EVENT SECTION ---------- -->
  <section class="event_section row_padding" id="event_slider">
    <div class="event_row left_padding">
      <div class="event_col">
        <div class="event_block">
          <div class="ai_product_col_box">
            <div class="ai_product_left">
              <div class="ai_product_title">
                <div class="site_title center_mob_title">
                  <p class="body_heading">Events</p>
                </div>
              </div>
              <div class="ai_product_content">
                <div class="site_content center_content">
                  <p class="small_body_font">
                    Host memorable events of your life in our exquisite and
                    spacious event spaces.
                  </p>
                </div>
              </div>
              <div class="ai_product_btn">
                <div class="bird bird--one">
                  <div class="btn_border block_cta">
                    <router-link to="/event" class="site_button">
                      <div class="site_button_div">
                        <span class="btn_text">Read More</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="ai_product_arrows">
                <ul>
                  <li class="ai_prev ai_pro_icon" @click="$refs.events.prev()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 227.096 227.096"
                      style="enable-background: new 0 0 227.096 227.096"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <polygon
                            points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723     146.933,181.902 152.835,187.811 227.096,113.55   "
                          />
                        </g>
                      </g>
                    </svg>
                  </li>
                  <li class="ai_next ai_pro_icon" @click="$refs.events.next()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 227.096 227.096"
                      style="enable-background: new 0 0 227.096 227.096"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <polygon
                            points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723     146.933,181.902 152.835,187.811 227.096,113.55   "
                          />
                        </g>
                      </g>
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div class="ai_product_right">
              <div class="main_box_block">
                <Carousel
                  ref="events"
                  class="events_outer"
                  :breakpoints="sliders.events.breakpoints"
                  :wrap-around="true"
                  :navigation-enabled="true"
                >
                  <Slide v-for="(event, index) in events" :key="index">
                    <div class="pro_box_block">
                      <div class="box_outter_box">
                        <div class="box_inner_box">
                          <div class="box_img_block">
                            <img :src="event.event_image[0].image" class="box_img" />
                            <div class="btn_block btn_center_block">
                              <div class="btn_border block_cta">
                                <div class="bird_hover_opacity bird bird--one">
                                  <router-link class="site_button" to="/events-rio" v-if="event.is_active">
                                    <div class="site_button_div">
                                      <span class="btn_text">Book Now</span>
                                    </div>
                                  </router-link>
                                  <router-link class="site_button" to="/photo#eventsbyrio" else>
                                    <div class="site_button_div">
                                      <span class="btn_text">Explore</span>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="box_data_block">
                            <div class="box_data">
                              <div
                                class="box_body_text text_center box_details_block"
                              >
                                <div class="box_details">
                                  <div class="box_title_text_box">
                                    <p class="box_title_text tc">{{ event.name }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- FILLER SECTION ---------- -->
  <Download />
  <!-- ----------- GELLARY SECTION ---------- -->
  <section class="gallery_section row_padding">
    <div class="gallery_row main_container">
      <div class="main_title_block">
        <div class="body_heading_block desk_heading">
          <p class="body_heading tc">Gallery</p>
        </div>
      </div>
      <div class="gallery_col">
        <div class="gallery_block">
          <div class="gallery_img_row hover14 grow_01 odd_gallery">
            <a
              :href="gimg_01"
              data-lightbox="models"
              data-title="home-gallery-1"
              class="gallery_imgs"
            >
              <img :src="gimg_01" class="gallery_img" />
            </a>
            <a
              :href="gimg_02"
              data-lightbox="models"
              data-title="home-gallery-2"
              class="gallery_imgs"
            >
              <img :src="gimg_02" class="gallery_img" />
            </a>
          </div>
          <div class="gallery_img_row hover14 grow_02 even_gallery">
            <a
              :href="gimg_03"
              data-lightbox="models"
              data-title="home-gallery-3"
              class="gallery_imgs"
            >
              <img :src="gimg_03" class="gallery_img" />
            </a>
            <a
              :href="gimg_04"
              data-lightbox="models"
              data-title="home-gallery-4"
              class="gallery_imgs"
            >
              <img :src="gimg_04" class="gallery_img" />
            </a>
          </div>
          <div class="gallery_img_row hover14 grow_03 odd_gallery">
            <a
              :href="gimg_05"
              data-lightbox="models"
              data-title="home-gallery-5"
              class="gallery_imgs"
            >
              <img :src="gimg_05" class="gallery_img" />
            </a>
            <a
              :href="gimg_06"
              data-lightbox="models"
              data-title="home-gallery-6"
              class="gallery_imgs"
            >
              <img :src="gimg_06" class="gallery_img" />
            </a>
          </div>
        </div>
        <div class="gallery_btn">
          <div class="bird bird--one">
            <div class="btn_border block_cta">
              <router-link to="/photo" class="site_button">
                <div class="site_button_div">
                  <span class="btn_text">View More</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="left_vector single_left_vector"></div>
    </div>
  </section>
  <!-- ----------- MAP SECTION ----------- -->
  <!-- <section class="map_section">
    <div class="map_row">
      <div class="map_col">
        <div class="map_block">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717.3140932001934!2d73.05998961533491!3d21.298601883886477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04169cc445207%3A0xac962a04a332fcd8!2sKKB%20Rio%20Colina%20-%20Club%20%26%20Villa!5e0!3m2!1sen!2sin!4v1668168436348!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </section> -->
  <!-- BODY SECTION END -->
  <Footer />
</template>

<script>
import banner1 from "../assets/images/banner01.jpg";
import banner2 from "../assets/images/banner02.jpg";
import banner3 from "../assets/images/banner03.jpg";
import single_title_png from "../assets/images/single_title_png.png";
import villa01 from "../assets/images/villa-img- (1).png";
import villa02 from "../assets/images/villa-img- (2).png";
import villa03 from "../assets/images/villa-img- (3).png";
import WRC01 from "../assets/images/WRC01.png";
import WRC02 from "../assets/images/WRC02.png";
import WRC03 from "../assets/images/WRC03.png";
import tab_01 from "../assets/images/tab_01.jpg";
import tab_02 from "../assets/images/tab_02.jpg";
import tab_03 from "../assets/images/tab_03.jpg";
import tab_point_1 from "../assets/images/tab/club-img-1.png";
import tab_point_2 from "../assets/images/tab/club-img-2.png";
import tab_point_3 from "../assets/images/tab/club-img-3.png";
import tab_point_4 from "../assets/images/tab/club-img-4.png";
import tab_point_5 from "../assets/images/tab/club-img-5.png";
import tab_point_6 from "../assets/images/tab/club-img-6.png";
import tab_point_7 from "../assets/images/tab/club-img-7.png";
import tab_point_8 from "../assets/images/tab/club-img-8.png";
import tab_point_9 from "../assets/images/tab/club-img-9.png";
import tab_point_10 from "../assets/images/tab/club-img-10.png";
import tab_point_11 from "../assets/images/tab/club-img-11.png";
import tab_point_12 from "../assets/images/tab/club-img-12.png";
import tab_point_13 from "../assets/images/tab/club-img-13.png";
import tab_point_14 from "../assets/images/tab/club-img-14.png";
import tab_point_15 from "../assets/images/tab/club-img-15.png";
import tab_point_16 from "../assets/images/tab/club-img-16.png";
import tab_point_17 from "../assets/images/tab/club-img-17.png";
import tab_point_18 from "../assets/images/tab/club-img-18.png";
import right_single_title from "../assets/images/right_single_title.png";
import work01 from "../assets/images/how-it-1.png";
import work02 from "../assets/images/how-it-2.png";
import work03 from "../assets/images/how-it-3.png";
import Luxury01 from "../assets/images/new_images/one_day_picknic.jpg";
// import Luxury01 from '../assets/images/Luxury01.jpg';
import Luxury02 from "../assets/images/new_images/wedding_funs_img.jpg";
// import Luxury02 from '../assets/images/Luxury02.jpg';
import Luxury03 from "../assets/images/new_images/co-event.jpg";
// import Luxury03 from '../assets/images/Luxury03.jpg';
import about_img_01 from "../assets/images/about_img_01.jpg";
import about_img_02 from "../assets/images/about_img_02.jpg";
import about_img_03 from "../assets/images/about_img_03.jpg";
import event01 from "../assets/images/event01.jpg";
import event02 from "../assets/images/new_images/enganement.jpg";
import haldi from "../assets/images/new_images/haldi.jpg";
import baby_shower from "../assets/images/event02.jpg";
import event03 from "../assets/images/event03.jpg";
import event04 from "../assets/images/event04.jpg";
import filler_img from "../assets/images/filler_img.png";
// import GP_btn_01Png from '../assets/images/GP_btn_01.png';
import GP_btn_01Png from "../assets/images/gp-img.png";
import AS_btn_01Png from "../assets/images/as-img.png";
import gimg_01 from "../assets/images/gallery/gimg_01.jpg";
import gimg_02 from "../assets/images/gallery/gimg_02.jpg";
import gimg_03 from "../assets/images/gallery/gimg_03.jpg";
import gimg_04 from "../assets/images/gallery/gimg_04.jpg";
import gimg_05 from "../assets/images/gallery/gimg_05.jpg";
import gimg_06 from "../assets/images/gallery/gimg_06.jpg";
import rio_video from "../assets/images/rio-video.mp4";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import Form from "vform";
import axios from "axios";

import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Download from "@/pages/Download.vue";

export default {
  name: "Home",
  data() {
    return {
      banner1: banner1,
      banner2: banner2,
      banner3: banner3,
      single_title_png: single_title_png,
      villa01: villa01,
      villa02: villa02,
      villa03: villa03,
      WRC01: WRC01,
      WRC02: WRC02,
      WRC03: WRC03,
      tab_01: tab_01,
      tab_02: tab_02,
      tab_03: tab_03,
      tab_point_1: tab_point_1,
      tab_point_2: tab_point_2,
      tab_point_3: tab_point_3,
      tab_point_4: tab_point_4,
      tab_point_5: tab_point_5,
      tab_point_6: tab_point_6,
      tab_point_7: tab_point_7,
      tab_point_8: tab_point_8,
      tab_point_9: tab_point_9,
      tab_point_10: tab_point_10,
      tab_point_11: tab_point_11,
      tab_point_12: tab_point_12,
      tab_point_13: tab_point_13,
      tab_point_14: tab_point_14,
      tab_point_15: tab_point_15,
      tab_point_16: tab_point_16,
      tab_point_17: tab_point_17,
      tab_point_18: tab_point_18,
      right_single_title: right_single_title,
      work01: work01,
      work02: work02,
      work03: work03,
      Luxury01: Luxury01,
      Luxury02: Luxury02,
      Luxury03: Luxury03,
      about_img_01: about_img_01,
      about_img_02: about_img_02,
      about_img_03: about_img_03,
      event01: event01,
      event02: event02,
      event03: event03,
      event04: event04,
      haldi: haldi,
      baby_shower: baby_shower,
      filler_img: filler_img,
      GP_btn_01Png: GP_btn_01Png,
      AS_btn_01Png: AS_btn_01Png,
      gimg_01: gimg_01,
      gimg_02: gimg_02,
      gimg_03: gimg_03,
      gimg_04: gimg_04,
      gimg_05: gimg_05,
      gimg_06: gimg_06,
      rio_video: rio_video,
      isClose: true,
      // adult: '',
      form: new Form({
        check_in_date: "",
        check_out_date: "",
        total_adult: "",
        total_child: "",
        qty: "",
        // booking: {
        //   total: 0,
        //   gst_tax: 0,
        //   extra_person_charge: 0,
        //   festival_charge: 0,
        //   gst_tax_percent: 0,
        // }
      }),
      villaList: [],
      token: localStorage.getItem("auth_cus_token"),
      villaDetail: [],
      sum: 0,
      vQty: 0,
      events: [],
      sliders: {
        villas: {
          breakpoints: {
            1440: {
              itemsToShow: 3,
              snapAlign: "center",
            },
            1024: {
              itemsToShow: 3,
              snapAlign: "center",
            },
            767: {
              itemsToShow: 1,
              snapAlign: "start",
            },
            480: {
              itemsToShow: 1,
              snapAlign: "start",
            },
          },
        },
        moments: {
          breakpoints: {
            1440: {
              itemsToShow: 3,
              snapAlign: "center",
            },
            1024: {
              itemsToShow: 3,
              snapAlign: "center",
            },
            767: {
              itemsToShow: 1,
              snapAlign: "start",
            },
            480: {
              itemsToShow: 1,
              snapAlign: "start",
            },
          },
        },
        events: {
          breakpoints: {
            1440: {
              itemsToShow: 2,
              snapAlign: "center",
            },
            1024: {
              itemsToShow: 2.5,
              snapAlign: "center",
            },
            767: {
              itemsToShow: 1,
              snapAlign: "start",
            },
            480: {
              itemsToShow: 1,
              snapAlign: "start",
            },
          },
        },
      },
    };
  },
  components: {
    Header,
    Footer,
    Datepicker,
    Carousel,
    Slide,
    Navigation,
    Download,
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    sessionStorage.removeItem("check_in_date");
    sessionStorage.removeItem("check_out_date");
    sessionStorage.removeItem("total_adult");
    sessionStorage.removeItem("total_child");
    sessionStorage.removeItem("qty");

    const addScript = document.createElement("script");
    addScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(addScript);

    this.getEvents();
  },
  methods: {
    async getEvents(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/get-events?page=" + page)
        .then((response) => {
          this.events = response.data.data.data;
        });
    },
    async submitForm() {
      this.loader = true;
      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/search-villa",
          {
            check_in_date: (this.form.check_in_date = moment(
              this.form.check_in_date
            ).format("YYYY-MM-DD")),
            check_out_date: (this.form.check_out_date = moment(
              this.form.check_out_date
            ).format("YYYY-MM-DD")),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          var villaList = response.data.data;
          for (let output of villaList) {
            this.sum += output.max_adult_limit * output.villa_quantity;
            this.vQty += output.villa_quantity;
          }
          if (this.form.total_adult > this.sum) {
            //error
            this.$toast.error("Max par person limit 9");
          } else if (this.form.qty > this.vQty) {
            //error
            this.$toast.error("Max par villa limit 1");
          } else {
            sessionStorage.setItem(
              "check_in_date",
              (this.form.check_in_date = moment(this.form.check_in_date).format(
                "YYYY-MM-DD"
              ))
            );
            sessionStorage.setItem(
              "check_out_date",
              (this.form.check_out_date = moment(
                this.form.check_out_date
              ).format("YYYY-MM-DD"))
            );
            sessionStorage.setItem("total_adult", this.form.total_adult);
            sessionStorage.setItem("total_child", this.form.total_child);
            sessionStorage.setItem("qty", this.form.qty);
            this.$router.push({ name: "villa-list" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    closeCal() {
      this.isClose = !this.isClose;
      if (this.isClose == false) {
        document.querySelector("dp__menu").style.visibility = "hidden";
        document.querySelector("dp__menu").style.transition =
          "all 0.5s linear !important";
        document.querySelector("dp__menu").style.height = "0px";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
.cls-1,
.cls-2 {
  fill: none;
}

.cls-2 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-dasharray: 3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dp__input {
  background: #e1ebd3 !important;
  border: 0 !important;
}

.dp__input_wrap {
  background: #e1ebd3;
}

.dp__main {
  position: relative;
  background: #e1ebd3;
  border-radius: 6px;
  display: flex;
  padding: 10px;
  width: 300px;
  height: 45px;
}

.booking_form_section svg.dp__input_icon {
  width: 18px;
  height: 18px !important;
  transform: translateY(-55%);
}

.events_outer {
  width: 100% !important;
}

.carousel .site_button {
  color: #fff !important;
  border-color: #fff !important;
}

.pro_box_block {
  width: 100%;
}

.booking_form_section {
  width: 90% !important;
  margin: 0 auto;
}

.carousel__prev,
.carousel__next {
  color: #fff !important;
}

.date-group .dp__main {
  padding: 0;
  width: 100%;
}

.date-group .dp__main div[role="textbox"] {
  width: 100%;
}

.date-group .dp__input_wrap {
  height: 100%;
}

.date-group .dp__pointer {
  height: 100%;
}

@media all and (max-width: 480px) {
  .multiple_img_block .m_img_01,
  .multiple_img_block .m_img_02 {
    display: none;
  }

  .m_img_03 {
    width: 100%;
    padding: 10px;
  }

  .wcu_row.main_container {
    padding: 10px 20px !important;
  }

  .p_tab_block .tabing_inner {
    margin-bottom: 15px !important;
  }

}

@media all and (max-width: 767px) {
  .dp__menu {
    width: 100%;
    left: 0 !important;
  }

  #villas_slider .carousel__slide,
  #moments_slider .carousel__slide {
    padding: 10px;
  }

  .dp__main {
    width: 100%;
  }

  .booking_form_section .elem-group.inlined {
    padding-right: 0px !important;
  }

  .wcu_section .data_inner {
    flex-wrap: wrap;
  }

  .gallery_block,
  .gallery_img_row {
    display: flex;
    flex-wrap: wrap;
  }

  .gallery_img_row a {
    width: 50% !important;
  }

  .gallery_col {
    margin-top: 0px;
  }

  .body_heading_block.desk_heading {
    padding-bottom: 10px;
    margin-top: 20px;
  }

  .event_section {
    padding-top: 60px !important;
  }

  .event_section .small_body_font {
    text-align: center;
  }
}

@media all and (min-width: 991px) {
  #villas_slider .carousel__prev,
  #villas_slider .carousel__next {
    display: none;
  }

  #moments_slider .carousel__prev,
  #moments_slider .carousel__next {
    display: none;
  }
}

.for_other {
  display: none;
}

@media all and (max-width: 1200px) {
  .for_desktop {
    display: none;
  }

  .for_other {
    display: block;
  }
}

@media all and (max-width: 1440px) {
  .booking_form_block form {
    flex-wrap: wrap;
    justify-content: center;
  }

  .booking_form_block button.book_button {
    padding: 15px 25px;
    border-radius: 15px;
    margin-top: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .booking_form_section .elem-group.inlined {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding: 10px !important;
  }

  .booking_form_section .field_form_input_box {
    display: flex;
  }

  .booking_form_section .elem-group.inlined.small_input {
    width: 33.33%;
  }

  .booking_form_section .dp__main {
    width: 100% !important;
  }

  .booking_form_section .field_form_input_box {
    flex-wrap: wrap;
  }

  .booking_form_section .field_block {
    width: 100% !important;
  }
}

@media only screen and (min-width: 981px) and (max-width: 1024px) {
  .booking_form_section .elem-group.inlined {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding: 10px !important;
  }

  .booking_form_section .field_form_input_box {
    display: flex;
  }

  .booking_form_section .elem-group.inlined.small_input {
    width: 33.33%;
  }

  .booking_form_section .dp__main {
    width: 100% !important;
  }

  .booking_form_section .field_form_input_box {
    flex-wrap: wrap;
  }

  .booking_form_section .field_block {
    width: 100% !important;
  }
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .booking_form_section .elem-group.inlined {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding: 10px !important;
  }

  .booking_form_section .field_form_input_box {
    display: flex;
  }

  .booking_form_section .elem-group.inlined.small_input {
    width: 33.33%;
  }

  .booking_form_section .dp__main {
    width: 100% !important;
  }

  .booking_form_section .field_form_input_box {
    flex-wrap: wrap;
  }

  .booking_form_section .field_block {
    width: 100% !important;
  }
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  .booking_form_section .elem-group.inlined {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding: 10px !important;
  }

  .booking_form_section .elem-group.inlined.small_input {
    width: 33.33%;
  }

  .booking_form_section .dp__main {
    width: 100% !important;
  }

  .booking_form_section .field_form_input_box {
    flex-wrap: wrap;
  }

  .booking_form_section .field_block {
    width: 100% !important;
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  .booking_form_section .elem-group.inlined {
    width: 50%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding: 10px;
  }

  .booking_form_section .elem-group.inlined.small_input {
    width: 33.33%;
  }

  .booking_form_section .dp__main {
    width: 100% !important;
  }

  .booking_form_section .field_form_input_box {
    flex-wrap: wrap;
  }

  .booking_form_section .field_block {
    width: 100% !important;
  }
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
}
</style>
